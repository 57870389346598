<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="
        `align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0`)
      " :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1 v-if="moduleType == 'sales'">Звіт про продажі за період</h1>
            <h1 v-if="moduleType == 'stock'">Звіт про залишки за період</h1>
        </v-card-title>

        <v-tabs v-model="tab" class="pb-1 card">
            <v-tab>
                <span v-if="moduleType == 'sales'">
                    <v-icon class="mr-2">mdi-chart-bar-stacked</v-icon>Звіт про продажі
                </span>
                <span v-if="moduleType == 'stock'">
                    <v-icon class="mr-2">mdi-chart-bar-stacked</v-icon>Звіт про залишки
                </span>
            </v-tab>
            <v-tab v-if="moduleType == 'stock'">
                <v-icon class="mr-2">mdi-image</v-icon>Фотозвіт
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card class="mb-4 px-0 px-md-2" outlined width="100%" elevation="4">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <h4 v-if="moduleType == 'sales'">Додати продажі за період*:</h4>
                                <h4 v-if="moduleType == 'stock'">Додати залишки на дату *:</h4>
                                <v-row v-if="moduleType == 'sales'">
                                    <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateFrom" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateTo" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row v-if="moduleType == 'stock'">
                                    <v-col cols="12" sm="6" class="d-flex align-center">
                                        <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDateFrom" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateFrom" @input="menu5 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <h4 class="py-4">По Програмі:</h4>
                                <div>
                                    <v-checkbox @change="checkShops()" class="shrink mr-2 mt-0" hide-details v-for="(el, key) in motivationList" :key="key" :label="el.name" v-model="motivations[el.id]"></v-checkbox>
                                    <div v-if="motivationList && !motivationList.length" class="caption">Немає діючих програм.</div>
                                </div>
                                <h4 class="py-4">Кіль-ть вказана у пакуваннях (блістер, bulk та ін.):</h4>
                                <div>
                                    <v-checkbox hide-details class="mt-0" v-model="indivisible" label="Так"></v-checkbox>
                                </div>
                                <div class="mt-4 mb-6 mb-md-0">
                                    <v-file-input accept=".xlsx" v-model="file" show-size hint="Формат Excel (.xlsx)" persistent-hint label="Додати файл *"></v-file-input>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-row>
                                    <v-col cols="12" sm="6" md="12" lg="8">
                                        <h4>Вкажіть номер стовбця, у якому знаходиться:</h4>
                                        <div>
                                            <v-text-field v-model="productIdent" :label="`Ідентифікатор товару *` + (sel_type ? sel_type == 'typhoon_id' ? `: Код товару ЮК` : (sel_type == 'artikul' ? `: Артикул товару` : ': IMEI/СН') : ``)" :disabled="!sel_type" :placeholder="sel_type ? sel_type == 'typhoon_id' ? `Код товару ЮК` : `Артикул товару` : ''"></v-text-field>
                                            <v-radio-group class="mt-n4" v-model="sel_type" row>
                                                <v-radio name="sel_type" label="Код товару ЮК" value="typhoon_id"></v-radio>
                                                <v-radio name="sel_type" label="Артикул товару" value="artikul"></v-radio>
                                                <v-radio name="sel_type" label="IMEI/СН" value="imei"></v-radio>
                                            </v-radio-group>
                                        </div>
                                        <div class="mt-n4">
                                            <v-text-field label="Назва товару" v-model="product_name"></v-text-field>
                                        </div>
                                        <div v-if="moduleType == 'sales'">
                                            <v-text-field label="Дата продажу" v-model="sale_date"></v-text-field>
                                        </div>
                                        <h4 v-if="moduleType == 'sales'" class="mt-4">Вкажіть номер стовбця, у якому знаходиться кіль-ть проданого товару *:</h4>
                                        <h4 v-if="moduleType == 'stock'" class="mt-4">Вкажіть номер стовбця, у якому знаходиться кіль-ть товару у залишку *:</h4>
                                        <v-sheet elevation="1" class="mt-4 pa-4">
                                            <v-row>
                                                <v-text class="caption ml-3 mt-2 grey--text">Розпізнавання стовбців</v-text>
                                                <v-text class="caption ml-3 mt-2">Якщо маєте коди-ідентифікатори магазинів у файлі, то для авторозпізнавання стовбців вкажіть номер рядку, в якому вони знаходяться:</v-text>
                                            </v-row>
                                        <v-row >
                                            <v-col cols="7" sm="7" lg="8">
                                                <v-text-field label="Код магазина" v-model="clientShopsCodeRow"></v-text-field>
                                            </v-col>
                                            <v-col cols="5" sm="5" lg="4" class="text-right">
                                                <v-btn @click="recognizeShops()" color="primary" densy :disabled="clientShopsCodeRow && file ? false : true " class="mt-4">Розпізнати</v-btn>
                                            </v-col>
                                        </v-row>
                                        </v-sheet>
                                        <v-checkbox @click="checkAllShops()" hide-details class="mr-2 mb-5" label="Вибрати всі магазини" v-model="allShops"></v-checkbox>
                                        <div v-for="(el, key) in shopsList" :key="key" class="d-flex">
                                            <v-checkbox @click="checkShopQty(el.shop_id)" :disabled="el.is_inactive" hide-details class="mr-2 mt-2" v-model="qtyS[el.shop_id]"></v-checkbox>
                                            <v-text-field :disabled="el.is_inactive || !qtyS[el.shop_id]" class="mt-n2" :label="el.name" v-model="qty[el.shop_id]"></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mt-4">
                        <v-btn color="primary" @click="downloadReport()" :loading="listLoading" :disabled="checkForm">до завантаження</v-btn>
                        <v-btn @click="clearForm()">Очистити</v-btn>
                    </v-card-actions>
                </v-card>
                <div v-if="moduleType == 'stock' && motivationList && motivationList.length">
                    <span class="mr-4 caption font-weight-bold">Допоміжні шаблони для завантаження:</span>
                    <span v-for="(el, key) in motivationList" :key="key" @click="downloadMotivationTemplate(el.id)" class="primary--text caption" title="Завантажити шаблон .xlsx" style="cursor: pointer">&bull; {{el.name}}
                    </span>
                </div>
            </v-tab-item>
            <v-tab-item>
                <v-card class="mb-4 px-0 px-md-2" outlined width="100%" elevation="4">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <h4 v-if="moduleType == 'stock'">Додати фото полиці на дату *:</h4>
                                <v-row>
                                    <v-col cols="12" sm="6" class="d-flex align-center">
                                        <v-menu v-model="menu7" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDatePhoto" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="datePhoto" @input="menu7 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <h4 class="py-4">По Програмі:</h4>
                                <div>
                                    <v-checkbox class="shrink mr-2 mt-0" hide-details v-for="(el, key) in motivationList" :key="key" :label="el.name" v-model="motivationsPhoto[el.id]"></v-checkbox>
                                    <div v-if="motivationList && !motivationList.length" class="caption">Немає діючих програм.</div>
                                </div>
                                <div class="mt-4">
                                    <v-select :items="shopsList" item-text="name" item-value="shop_id" label="Полиця Торгової точки *" v-model="shopPhoto" clearable></v-select>
                                </div>
                                <div>
                                    <v-file-input :counter="max" :rules="rules" prepend-icon="mdi-camera" accept=".jpg" chips multiple v-model="photoFiles" show-size :hint="`Формат .jpg, але не більше ${max}`" persistent-hint label="Додати фото*"></v-file-input>
                                </div>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mt-4">
                        <v-btn color="primary" @click="downloadPhotoReport()" :loading="listLoading" :disabled="checkPhotoForm">Завантажити</v-btn>
                        <v-btn @click="clearPhotoForm()">Очистити</v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <!-- Фильтр -->
        <h4 class="second--text mt-8 mb-4 title-2">Звіти:</h4>
        <v-card width="100%" class="mb-4 blue lighten-5 px-0 px-md-2" flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                        <h4 v-if="moduleType == 'sales'">Період продажів:</h4>
                        <h4 v-if="moduleType == 'stock'">Дата залишків:</h4>
                        <v-row>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedDateFromS" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateFromS" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedDateToS" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateToS" @input="menu4 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <div>
                            <v-select :items="categoryItems" label="Категорія" v-model="categoryS" clearable></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-4">Магазин:</h4>
                        <div>
                            <v-checkbox @click="checkAllShopsV()" hide-details class="mr-2 mb-5" label="Вибрати всі магазини" v-model="allShopsV"></v-checkbox>
                            <v-checkbox hide-details v-for="(el, key) in shopsList" :key="key" class="mr-2 mt-0 mb-5" v-model="shopS[el.shop_id]" :label="el.name"></v-checkbox>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-4">
                <v-btn color="primary" @click="loadList()">Шукати</v-btn>
                <v-btn @click="clearSearchForm()">Очистити</v-btn>
            </v-card-actions>
        </v-card>
        <div class="body-2 text-right px-0 px-md-2 my-2">
            Знайдено: {{ totalItems }}
        </div>

        <v-data-table v-if="moduleType == 'sales'" multi-sort :loading="listLoading" :headers="filteredHeadersSales" class="elevation-1 report" :items="salesList" :footer-props="footerProps" :options.sync="options" mobile-breakpoint="0">
            <template v-slot:item.start_date="{ item }">
                {{ toDate(item.start_date) }}
            </template>
            <template v-slot:item.finish_date="{ item }">
                {{ toDate(item.finish_date) }}
            </template>
            <template v-slot:item.category="{ item }">
                <span v-html="item.category"></span>
            </template>
            <template v-slot:item.download="{ item }">
                <v-btn text @click="exportR(item)" class="px-0" :disabled="xlsLoading">
                    <v-icon color="green darken-3" :small="$vuetify.breakpoint.smAndDown ? true : false">{{exclelIcon}}</v-icon>
                </v-btn>
            </template>
            <template v-slot:no-data> Немає данних </template>
            <template v-slot:loading> Зачекайте... </template>
        </v-data-table>

        <v-data-table v-if="moduleType == 'stock'" multi-sort :loading="listLoading" :headers="filteredHeadersStock" class="elevation-1 report" :items="stockList" :footer-props="footerProps" :options.sync="options" mobile-breakpoint="0">
            <template v-slot:item.delete="{ item }">
                <v-btn icon title="Видалити звіт" @click="deleteDialogStock(item)">
                    <v-icon color="primary" :small="$vuetify.breakpoint.smAndDown ? true : false">mdi-delete-forever</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.date="{ item }">
                {{ toDate(item.date) }}
            </template>
            <template v-slot:item.category="{ item }">
                <span v-html="item.category"></span>
            </template>
            <template v-slot:item.photo="{ item }">
                <div v-if="item.photo">
                    <a v-for="el in item.photo.split(',')" :key="el" :href="el.split('|')[1]" :title="el.split('|')[0]" class="mr-2" target="_blank">
                        <v-badge color="link" right bottom bordered avatar offset-x="10" offset-y="10" icon="mdi-download-outline">
                            <v-icon color="link">mdi-image-album</v-icon>
                        </v-badge>
                    </a>
                    <v-btn icon v-if="item.photoGroupId" title="Видалити всі фото" @click="deletePhotoDialogStock(item)">
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:item.download="{ item }">
                <v-btn text @click="exportS(item)" icon :disabled="xlsLoading">
                    <v-icon color="green darken-3" :small="$vuetify.breakpoint.smAndDown ? true : false">{{exclelIcon}}</v-icon>
                </v-btn>
            </template>
            <template v-slot:no-data> Немає данних </template>
            <template v-slot:loading> Зачекайте... </template>
        </v-data-table>
    </v-card>

    <!-- Report Dialog -->
    <ReportsDialog />

    <v-dialog v-model="errorsDialog" width="600" max-width="100%" class="error">
        <v-card width="100%">
            <v-card-title class="error white--text">Помилка!</v-card-title>
            <v-card-text class="mt-6">
                <p v-html="errorsTxt || errorText"></p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="errorsDialog=false">Закрити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Ви хочете видалити звіт зі всіма фото?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="deleteStock()">Видалити</v-btn>
                <v-btn text @click="confirmDialog=false">Закрити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmPhotoDialog" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Ви хочете видалити всі фото?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="deletePhoto()">Видалити</v-btn>
                <v-btn text @click="confirmPhotoDialog=false">Закрити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="stockDocsDialog" persistent width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="success white--text">Дякуємо! Звіт завантажено успішно</v-card-title>
            <v-card-text class="mt-6">
                <div v-if="stockDocs.length">
                    <h3>Увага!</h3>
                    <div class="subtitle mb-4">Був створений резерв для поповнення матриць за вказаними програмами:</div>
                    <div v-for="(el, idx) in stockDocs" :key="idx">
                        {{el.programName}} : <a target="_blank" :href="`/checkout/success/${el.id}`">{{el.id}}</a>
                    </div>
                </div>
                <!-- {{stockDocs}} -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="stockDocsDialog=false">Закрити</v-btn>
                </v-card-actions>

            </v-card-text>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    mdiMicrosoftExcel
} from '@mdi/js'

import ReportsDialog from "@/components/ReportsDialog.vue";

export default {
    components: {
        ReportsDialog,
    },
    data: () => ({
        exclelIcon: mdiMicrosoftExcel,
        confirmPhotoDialog: false,
        tab: null,
        show: false,
        errorText: '',
        indivisible: false,
        datePhoto: "",
        dateFrom: "",
        dateTo: "",
        dateFromS: "",
        dateToS: "",
        categoryS: "",
        shopS: {},
        shopPhoto: '',
        max: 10,
        photoFiles: [],
        allShops: false,
        allShopsV: false,
        confirmDialog: false,
        deleteStockItem: undefined,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu7: false,
        sel_type: undefined,
        file: undefined,
        product_name: "",
        sale_date: "",
        productIdent: "",
        motivations: {},
        motivationsPhoto: {},
        qty: {},
        qtyS: {},
        search: "",
        clientShopsCodeRow: "",
        errorsDialog: false,
        options: {
            page: 1,
            itemsPerPage: 40,
        },
        footerProps: {
            "items-per-page-options": [20, 40, 80, 100, 200],
            "items-per-page-text": "на стор.",
        },
        xlsLoading: false,
        fileName: "",
        fileBlobURL: "",
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml",
        },
        headersSales: [{
                text: "Продаж З",
                align: "center",
                value: "start_date",
                sortable: true,
                width: "10%",
            },
            {
                text: "Продаж ПО",
                align: "center",
                value: "finish_date",
                sortable: true,
                width: "10%",
            },
            {
                text: "ТТ",
                align: "left",
                value: "shop_name",
                sortable: true,
                width: "35%",
            },
            {
                text: "Категорія",
                align: "center",
                value: "category",
                sortable: true,
                width: "25%",
            },
            {
                text: "",
                align: "center",
                value: "download",
                sortable: true,
                width: "5%",
            },
        ],
        headersStock: [{
                text: "",
                align: "left",
                value: "delete",
                sortable: false,
                width: "5%",
            },
            {
                text: "Дата",
                align: "center",
                value: "date",
                sortable: true,
                width: "10%",
            },
            {
                text: "ТТ",
                align: "left",
                value: "shop_name",
                sortable: true,
                width: "35%",
            },
            {
                text: "Категорія",
                align: "center",
                value: "category",
                sortable: true,
                width: "25%",
            },
            {
                text: "Фото полиці",
                align: "center",
                value: "photo",
                sortable: true,
                width: "25%",
            },
            {
                text: "",
                align: "center",
                value: "download",
                sortable: true,
                width: "5%",
            },
        ],
    }),
    methods: {
        ...mapActions([
            "touch",
            "getContactInfo",
            "loadSalesReportsList",
            "loadStockReportsList",
            "getMotivationList",
            "getShopsForReport",
            "exportSales",
            "exportStock",
            "sendSalesReportForm",
            "sendStockReportForm",
            "deleteStockReport",
            "setSnackbar",
            "sendPhotoReportForm",
            "deleteStockPhoto",
            "downloadTemplate",
            "sendRecognizeShops"
        ]),
        ...mapGetters(["catalogTree"]),
        loadList() {
            this.options.page = 1;
            this.touch().then(() => {
                if (this.moduleType == 'sales') {
                    this.loadSalesReportsList({
                        start_date: this.dateFromS,
                        finish_date: this.dateToS,
                        category: this.categoryS,
                        shop_ids: Object.keys(this.shopS)
                            .filter((el) => el && this.shopS[el])
                            .join(","),
                    })
                }
                if (this.moduleType == 'stock') {
                    this.loadStockReportsList({
                        start_date: this.dateFromS,
                        finish_date: this.dateToS,
                        category: this.categoryS,
                        shop_ids: Object.keys(this.shopS)
                            .filter((el) => el && this.shopS[el])
                            .join(","),
                    })
                }
            })
        },
        toDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        clearForm() {
            this.dateFrom = ""
            this.dateTo = ""
            this.sel_type = undefined
            this.file = undefined
            this.product_name = ""
            this.sale_date = ""
            this.productIdent = ""
            this.qty = {}
            this.qtyS = {}
            this.indivisible = ""
            this.motivations = {}
        },
        clearSearchForm() {
            this.dateFromS = ""
            this.dateToS = ""
            this.categoryS = ""
            this.shopS = {}

        },
        clearPhotoForm() {
            this.datePhoto = ''
            this.shopPhoto = ''
            this.motivationsPhoto = {}
            this.photoFiles = []
        },
        recognizeShops() {
            this.touch()
                .then(() => {
                    let formData = new FormData()
                    formData.append("file", this.file, this.file.name)
                    formData.append("clientShopsCodeRow", this.clientShopsCodeRow)
                    this.sendRecognizeShops(formData)
                        .then(data => {
                            if (data.error) {
                                this.setSnackbar(['error', 'Помилка розпізнання!'])
                            } else {
                                let count = 0
                                for (let el in data) {
                                    count++
                                    this.$set(this.qty, el, data[el])
                                    this.$set(this.qtyS, el, true)
                                }
                                if (count) {
                                    this.setSnackbar(['success', 'Магазини розпізнано!'])
                                } else {
                                    this.setSnackbar(['error', 'Магазини не розпізнано!'])
                                }
                            }
                        })
                })
                .catch((error) => console.log(error));
        },
        exportR(item) {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.xlsLoading = true;
            this.touch()
                .then(() => {
                    this.exportSales(item).then((data) => {
                        this.saveData(data, "sales-report.xlsx");
                        this.xlsLoading = false;
                    });
                })
                .catch((error) => console.log(error));
        },
        exportS(item) {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.xlsLoading = true;
            this.touch()
                .then(() => {
                    this.exportStock(item).then((data) => {
                        this.saveData(data, "stock-report.xlsx");
                        this.xlsLoading = false;
                    });
                })
                .catch((error) => console.log(error));
        },
        saveData(data, fileName) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var buf = Buffer.from(data, "base64");
            const blob = new Blob([buf], {
                type: this.mime["xlsx"],
            });
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },
        checkShops() {
            this.touch()
                .then(() => {
                    this.getShopsForReport({
                            motivations: Object.keys(this.motivations)
                                .filter((el) => el && this.motivations[el])
                                .join(","),
                        })
                        .then(data => {
                            for (let el of data) {
                                if (el.is_inactive) {
                                    this.$set(this.qty, el.shop_id, '')
                                    this.$set(this.qtyS, el.shop_id, '')
                                }
                            }
                        })
                })
                .catch((error) => console.log(error));
            return true;
        },

        downloadReport() {
            this.touch()
                .then(() => {
                    let formData = new FormData();
                    const motivations = Object.keys(this.motivations)
                        .filter((el) => el && this.motivations[el])
                        .join(",")

                    if (this.file) {
                        formData.append("file", this.file, this.file.name);
                    }
                    formData.append("dateFrom", this.dateFrom);
                    formData.append("dateTo", this.dateTo);
                    formData.append("sel_type", this.sel_type || 'typhoon_id');
                    formData.append("indivisible", this.indivisible);
                    formData.append("product_name", this.product_name);
                    formData.append("sale_date", this.sale_date);
                    formData.append("productIdent", this.productIdent || 1);
                    for (let shopId in this.qtyS) {
                        if (this.qtyS[shopId]) {
                            formData.append("qty", shopId + "|" + this.qty[shopId] || 2);
                        }
                    }
                    formData.append("motivations", motivations);
                    if (this.moduleType == 'sales') {
                        this.sendSalesReportForm(formData)
                            .then(data => {
                                if (data.errors) {
                                    this.errorsDialog = true
                                } else {
                                    this.$store.commit('setReportsDialog', true)
                                }
                            })
                    } else {
                        this.sendStockReportForm(formData)
                            .then(data => {
                                if (data.errors) {
                                    this.errorsDialog = true
                                } else {
                                    this.$store.commit('setReportsDialog', true)
                                    this.$store.commit('setStockDocs', [])
                                }
                            })
                    }

                })
                .catch((error) => console.log(error));
        },
        downloadPhotoReport() {
            this.touch()
                .then(() => {
                    let formData = new FormData();
                    if (this.photoFiles) {
                        for (let idx in this.photoFiles) {
                            formData.append("photos", this.photoFiles[idx], this.photoFiles[idx].name);
                        }
                    }
                    formData.append("date", this.datePhoto);
                    formData.append("shopPhoto", this.shopPhoto);

                    formData.append(
                        "motivations",
                        Object.keys(this.motivationsPhoto)
                        .filter((el) => el && this.motivationsPhoto[el])
                        .join(",")
                    )
                    this.errorText = ''
                    this.sendPhotoReportForm(formData)
                        .then(data => {
                            if (data.errors && data.errors.length) {
                                console.log(data.errors)
                                this.errorsDialog = true
                                this.errorText = 'Наступні файли вже були заванажені раніше:<br>'
                                for (let el of data.errors) {
                                    this.errorText += `<li>${el.originalname}</li>`
                                }
                            }
                            if (data.error && data.error == 1) {
                                this.setSnackbar(['error', 'Немає залишків на цю дату!'])
                            } else {
                                this.setSnackbar(['success', 'Фотозвіт завантажено!'])
                                this.loadList()
                            }
                        })

                })
                .catch((error) => console.log(error));
        },
        deleteDialogStock(data) {
            this.deleteStockItem = data
            this.confirmDialog = true
        },
        deletePhotoDialogStock(data) {
            this.deleteStockItem = data
            this.confirmPhotoDialog = true
        },
        deleteStock() {
            this.confirmDialog = false
            const data = this.deleteStockItem
            this.touch()
                .then(() => {
                    this.deleteStockReport(data)
                        .then(data => {
                            this.loadList()
                            this.setSnackbar(['success', 'Звіт видалено!'])
                        })
                })
                .catch((error) => console.log(error));
        },
        deletePhoto() {
            this.confirmPhotoDialog = false
            const data = this.deleteStockItem
            this.touch()
                .then(() => {
                    this.deleteStockPhoto(data)
                        .then(data => {
                            this.loadList()
                            this.setSnackbar(['success', 'Фото видалено!'])
                        })
                })
                .catch((error) => console.log(error));
        },
        downloadMotivationTemplate(id) {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.xlsLoading = true;
            this.touch()
                .then(() => {
                    this.downloadTemplate({
                        motivations: id
                    }).then((data) => {
                        this.saveData(data, "template.xlsx");
                        this.xlsLoading = false;
                    });
                })
                .catch((error) => console.log(error));
        },
        checkShopQty(shopId) {
            if (!this.qtyS[shopId]) {
                this.qty[shopId] = ''
            }
        },
        checkAllShops() {
            for (let el of this.shopsList) {
                if (!el.is_inactive) {
                    const shopId = el.shop_id
                    this.$set(this.qtyS, shopId, this.allShops)
                }
            }
        },
        checkAllShopsV() {
            for (let el of this.shopsList) {
                const shopId = el.shop_id
                this.$set(this.shopS, shopId, this.allShopsV)
            }
        }
    },
    created() {
        this.getContactInfo()
            .then((data) => {
                if (!data.isSellToEmployee) {
                    this.show = true;
                    this.loadList()
                    this.getShopsForReport()
                    this.getMotivationList()
                } else {
                    this.$router.push("/403")
                }
            })
            .catch((error) => console.log(error))
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo;
        },
        checkForm() {
            const shopNum = Object.keys(this.qtyS)
                .filter((el) => el && this.qty[el])
                .join(",")
            const shopS = Object.keys(this.qtyS)
                .filter((el) => el && this.qtyS[el])
                .join(",")

            const motivationsMode = Object.keys(this.motivations)
                .filter((el) => el && this.motivations[el])
                .join(",")
            if (this.moduleType == 'sales') {
                if (motivationsMode) {
                    if (this.file) {
                        return this.dateFrom && this.dateTo && this.productIdent && shopNum && shopNum == shopS ? false : true;
                    } else {
                        return this.dateFrom && this.dateTo && shopS ? false : true;
                    }
                } else {
                    return this.file && this.dateFrom && this.dateTo && this.productIdent && shopNum && shopNum == shopS ? false : true;
                }
            } else {
                if (motivationsMode) {
                    if (this.file) {
                        return this.dateFrom && this.productIdent && shopNum && shopNum == shopS ? false : true;
                    } else {
                        return this.dateFrom && shopS ? false : true;
                    }
                } else {
                    return this.file && this.dateFrom && this.productIdent && shopNum && shopNum == shopS ? false : true;
                }
            }
        },
        checkPhotoForm() {
            const motivationsPhoto = Object.keys(this.motivationsPhoto)
                .filter((el) => el && this.motivationsPhoto[el])
                .join(",")
            return this.datePhoto && this.shopPhoto && motivationsPhoto && this.photoFiles.length && this.photoFiles.length <= 10 ? false : true

        },
        formatedDateFrom() {
            return this.toDate(this.dateFrom);
        },
        formatedDateTo() {
            return this.toDate(this.dateTo);
        },
        formatedDatePhoto() {
            return this.toDate(this.datePhoto);
        },
        formatedDateFromS() {
            return this.toDate(this.dateFromS);
        },
        formatedDateToS() {
            return this.toDate(this.dateToS);
        },
        motivationList() {
            return this.$store.state.reports.motivationList;
        },
        salesList() {
            return this.$store.state.reports.salesList;
        },
        stockList() {
            return this.$store.state.reports.stockList;
        },
        stockDocs() {
            return this.$store.state.reports.stockDocs
        },
        stockDocsDialog: {
            get() {
                return this.$store.state.reports.stockDocsDialog
            },
            set(newN) {
                return this.$store.commit('setStockDocsDialog', newN)
            }
        },
        totalItems() {
            if (this.$route.name == "StockReports") {
                return this.stockList.length
            }
            if (this.$route.name == "SalesReports") {
                return this.salesList.length
            }
        },
        shopsList() {
            return this.$store.state.reports.shopsList;
        },
        moduleType() {
            if (this.$route.name == "StockReports") {
                return 'stock'
            }
            if (this.$route.name == "SalesReports") {
                return 'sales'
            }
        },
        filteredHeadersSales() {
            return this.headersSales.filter((h) => {
                return !h.hidden ?
                    this.$vuetify.breakpoint.xs ?
                    !h.hiddenXs :
                    this.$vuetify.breakpoint.smAndDown ?
                    !h.hiddenSm :
                    true :
                    false;
            });
        },
        filteredHeadersStock() {
            return this.headersStock.filter((h) => {
                return !h.hidden ?
                    this.$vuetify.breakpoint.xs ?
                    !h.hiddenXs :
                    this.$vuetify.breakpoint.smAndDown ?
                    !h.hiddenSm :
                    true :
                    false;
            });
        },
        listLoading() {
            return this.$store.state.reports.loading;
        },
        categoryItems() {
            return (
                this.catalogTree() &&
                this.catalogTree().menuItems &&
                this.catalogTree().menuItems.map((el) => {
                    return {
                        text: el.text,
                        value: el.link,
                    };
                })
            );
        },
        errorsTxt() {
            const errors = this.$store.state.reports.reportData.errors || []
            return errors.map(el => `<li>${el}`).join('')
        },
        rules() {
            const rules = [];

            if (this.max) {
                const rule = (v) =>
                    (v || "").length <= this.max ||
                    `A maximum of ${this.max} characters is allowed`;

                rules.push(rule);
            }

            return rules;
        },
    },
    watch: {
        '$route.name'() {
            this.loadList()
        }
    }
};
</script>

<style>
@media(max-width: 599px) {

    .card .v-slide-group__prev,
    .card .v-slide-group__next {
        display: none !important;
    }
}

@media (max-width: 512px) {
    .v-data-table.report>.v-data-table__wrapper td {
        padding: 0 2px;
        font-size: 0.75rem !important;
        font-weight: 300;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;
    }
}
</style>
